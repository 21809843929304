import PropTypes from 'prop-types'
import React from 'react'
import { useQueryParam, NumberParam } from "use-query-params";


// <li>
//   <button
//     onClick={() => {
//       props.onOpenArticle('about')
//     }}
//   >
//     About
//   </button>
// </li>


function AweberForm() {
  const signup_success = useQueryParam("signup_success", NumberParam)[0];
  if(signup_success === 1){
    return(<p className='signup_success'>Thank you for signing up!</p>)
  } else{
    return(
       <div className='aweber-form'>
        Sign up below to be notified before I list an item for sale!
        <form action="https://www.aweber.com/scripts/addlead.pl" method="post">
          <input type="hidden" name="listname" value="awlist5918955" />
          <input type="hidden" name="redirect" value="https://www.thecarddad.com/?signup_success=1" />
          <input type="hidden" name="meta_adtracking" value="" />
          <input type="hidden" name="meta_message" value="1" />
          <input type="hidden" name="meta_required" value="email" />
          <input type="hidden" name="meta_forward_vars" value="0" />

          <input type="text" name="name" placeholder="Name" />
          <input type="text" name="email" placeholder="Email" />
          <br />
          <input name="submit" value="Subscribe" type="submit" />
        </form>
      </div>)
  }
}

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-address-card"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Card Dad</h1>
        <p>
          I'm a dad who collects baseball, basketball and football cards.<br />
          I regularly come across cool and interesting cards, if you'd like to take a look follow me on <a href='https://www.instagram.com/card_dad/'>Instagram</a> or join my email list to be notified first of anything new.
        </p>
        <AweberForm />
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('instagram')
            }}
          >
            Instagram&nbsp;<i className="fa fa-instagram"></i>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              window.open('https://www.ebay.com/sch/i.html?_ftrt=901&_fsradio=%26LH_SpecificSeller%3D1&_sop=12&_sadis=15&_saslop=1&_fss=1&_dmd=1&_sasl=jm1383&_osacat=0&_ipg=50&_ftrv=1&_from=R40&_trksid=p2045573.m570.l1313&_nkw=&_sacat=0')
            }}
          >
            Ebay <i className="fa fa-gavel"></i>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact&nbsp;<i className="fa fa-envelope-o"></i>
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
