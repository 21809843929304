import React from 'react'
import Image from 'gatsby-image'
import useInstagram from '../hooks/use-instagram'


const Instagram = () => {
    const instaPics = useInstagram();
    return (
       <>
        <div className='insta-wrapper'>
            {/* we have done spread in useInstagram hook, so fluid is on top */}
            {instaPics.map(pic => (
                <Image className='instag-img' fluid={pic.node.localImage.childImageSharp.fluid} />
            ))}
        </div>
        <a href={`https://www.instagram.com/card_dad`}>See more on Instagram &rarr;</a>
       </>
    )
}
export default Instagram