import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import AWS from "aws-sdk"

const AWSConf = {
  "region": process.env.GATSBY_REGION,
  "accessKeyId": process.env.GATSBY_accessKeyId,
  "secretAccessKey": process.env.GATSBY_secretAccessKey
};
AWS.config.update(AWSConf);

export default function ContactForm() {
    const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit(values, { resetForm }) {
      var params = {
        Message: JSON.stringify(values) /* required */,
        TopicArn: process.env.GATSBY_arn /* required */,
      }

      var publishTextPromise = new AWS.SNS({apiVersion: '2010-03-31'})
        .publish(params)
        .promise();

      publishTextPromise
        .then(function (data) {
          resetForm()  //reset the form
        })
        .catch(function (err) {
          console.error(err, err.stack)
        })

    },
  })

  return (
    <form method="post" onSubmit={formik.handleSubmit}>
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          placeholder="Your name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values.name} />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          placeholder="Your email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          id="message"
          rows="4"
          onChange={formik.handleChange}
          value={formik.values.message}></textarea>
      </div>
      <ul className="actions">
        <li>
          <input type="submit" value="Send Message" className="special" />
        </li>
        <li>
          <input type="reset" value="Reset" />
        </li>
      </ul>
      {formik.errors.email ? (
        <span className="errorText">{formik.errors.email}</span>
      ) : null}
    </form>
  )
}
