import {graphql, useStaticQuery} from 'gatsby'
import _get from "lodash/get";

const useInstagram = () => {
    const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent {
        edges {
          node {
            localImage {

              childImageSharp {
                fluid(maxWidth: 600, quality: 90, webpQuality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
        }
      }
    }
  }
  `)

  return _get(data, 'allInstagramContent.edges')
}
export default useInstagram